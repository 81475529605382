@import "../colors.scss";
@import "./themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

@import "../../../../../config/styles/colors.scss";
@import "../../../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.onBoard_Container.ant-modal {
    width: 100% !important;
    top: 5% !important;
    position: relative;

    @media screen and (max-width:576px){
        top: 100px !important;
    }
}

.onBoard_Container {
    max-width: 1096px !important;
    max-height: 616px !important;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.onBoard_Container .onboard-video {
    max-width: 1096px!important;
    width: 100%;
    height: 100%;
}

.onBoard_Container .ant-modal-close-x {
    color: #FFFFFF;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11111;
}

.onBoard_Container .ant-modal-body {
    padding: 0!important;
    overflow: hidden;
}

.onBoard_Container .ant-modal-content {
    background-color: transparent !important;
    height: 100%;
    width: 100%;
}

.lightTipModal{
}


.darkTipModal{

}



.onBoard_Container .layout_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //height: 616px;
    max-width: 1096px;
    background-color: rgba(0,0,0,0.5);
    z-index: 88;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .get_start_btn {
        border-radius: 55px;
        width: 300px;
        height: 100px;
        background-color: #6C5ECF;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 30px;
        line-height: 49px;
        border: none;
        margin-bottom: 32px;
        letter-spacing: 1.5px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: 0.5s;

        &:hover {
            span {
                opacity: 1;
            }

            .first_star {
                bottom:5px;
                right: 5px;
            }

            .second_star {
                top:-5px;
                right: 45px;
            }

            .third_star {
                left:5px;
                top:5px;
            }

            .fourth_star{
                bottom: 5px;
                left: 55px;
            }

            .fifth_star {
                top: -15px;
                right: 52%;
            }

            .first_slide {
                opacity: 1;
                left: 150%;
                top: -100px;
            }
            .second_slide {
                opacity: 1;
                left: 150%;
                top: -100px;
            }
        }

        .first_slide {
            width: 24px;
            height: 190%;
            background-color: #FFFFFF;
            position: absolute;
            left: -100px;
            top: 0;
            bottom: 0;
            margin-right: 5px;
            transition: 0.4s;
            transform: rotate(-30deg);
        }

        .second_slide {
            width: 10px;
            height: 180%;
            background-color: #FFFFFF;
            position: absolute;
            left: -90px;
            top: 0;
            bottom: 0;
            margin-left: 27px;
            transition: 0.4s;
            transform: rotate(-30deg);
        }
    }

    .replay_btn {
        cursor: pointer;
        transition: 0.5s;
        z-index: 8888;

        // &:hover {
        //     transform: rotate(360deg);
        // }
    }

    span {
        position: absolute;
        transition: 0.5s;
        opacity: 0;

        &.first_star {
            bottom:0;
            right: 0;
        }

        &.second_star {
            top:0;
            right: 40px;
        }

        &.third_star {
            left:0;
            top:0;
        }

        &.fourth_star{
            bottom: 0;
            left: 50px;
        }

        &.fifth_star {
            top: -10px;
            right: 50%;
        }
    }


    @media screen and (max-width:576px) {
        // background-color: blanchedalmond;
        // bottom: 0;
        top:0;
        // justify-content: flex-start;
        max-height: 200px;

        .get_start_btn {
            max-width: 150px;
            height:50px;
            border-radius: 15px;
            font-size: 1rem;
            padding: 0 2px
        }
        
    }
}


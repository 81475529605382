@import "../../../../../config/styles/colors.scss";
@import "../../../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.Tooltip_Container.ant-modal {
    max-width: 355px;

    @media screen and (max-width:576px){
        display: none;
    }
}

.Tooltip_Container {
    background-color: #6C5ECF;
    padding: 16px;
    border-radius: 10px;

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {
        padding: 0!important;
        overflow: hidden;
    }

    .ant-modal-content {
        background-color: transparent !important;
        height: 100%;
        width: 100%;
        box-shadow: none;
    }


    .tooltip_header {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 8px;
        line-height: 24.5px;
        letter-spacing: 1px;
    }

    .tooltip_body{
        margin-bottom: 16px;
        color: #D1D1D1;
        font-size:14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.7px;
    }

    .tooltip_note {
        color: #fff;
        font-weight: 400;
        line-height: 20px;
        font-size: 14px;
        background: #483E84;
        padding: 8px;
        border-radius: 8px;
    }

    .tooltip_btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
    }

    .skip_btn {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #B2ACE0;
        font-weight: 400;
    }

    .next_btn {
        color: #FFFFFF;
        font-weight: 700;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        letter-spacing: 0.8px;
        
        svg {
            margin-left: 5px;
        }
    }

    .tooltip_arrow_down {
        background-color: #6C5ECF;
        width: 38px;
        height:38px;
        position: absolute;
        top: 100%;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
    }

    .tooltip_arrow_down_center {
        background-color: #6C5ECF;
        width: 38px;
        height:38px;
        position: absolute;
        top: 100%;
        left: 44%;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
    }

    .tooltip_arrow_left {
        background-color: #6C5ECF;
        width: 38px;
        height:38px;
        position: absolute;
        top: 0;
        right: 100%;
        clip-path: polygon(100% 0, 0 51%, 100% 100%);
    }

    .tooltip_arrow_down_right {
        background-color: #6C5ECF;
        width: 38px;
        height:38px;
        position: absolute;
        top: 100%;
        right:0;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
    }

    .tooltip_arrow_right {
        background-color: #6C5ECF;
        width: 38px;
        height:38px;
        position: absolute;
        top: 0;
        left: 100%;
        clip-path: polygon(0 0, 0 100%, 100% 52%);
    }
    .tooltip_arrow_right_top {
        background-color: #6C5ECF;
        width: 38px;
        height:38px;
        position: absolute;
        top: 55%;
        left: 100%;
        clip-path: polygon(0 0, 0 100%, 100% 52%);
    }

    .final_tooltip {
        background-color: #5549AC;
        color: #FFFFFF;
        font-weight:700;
        font-size: 16px;
        line-height: 22px;
        border: none;
        outline: none;
        border-radius: 14px;
        width: 100%;
        padding: 10px;
        cursor: pointer;
    }

    .indicator_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(122, 122, 147, 1);
        margin: 0 8px;
    }

    .active_indicator {
        background: rgba(217, 217, 217, 1);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 4px;
    }
}

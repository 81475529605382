@import "../../../config/styles/colors.scss";
@import "../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.tabNavigator {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  @include themed() {
    background: t($boxColor);
  }
  z-index: 9;
  /* height: 50px; */
}
.tabNavigator ul {
  text-align: center;
  padding-top: 5px !important;
  height: 60px;
}
.tabNavigator ul li {
  margin-bottom: 0 !important;
  border: none;
  padding: 0 10px;
  font-size: 9px !important;
}
.tabNavigator .ant-menu-horizontal {
  line-height: 35px;
}
.tabNavigator .ant-menu-horizontal > .ant-menu-item-active,
.tabNavigator .ant-menu-horizontal > .ant-menu-item-open,
.tabNavigator .ant-menu-horizontal > .ant-menu-item-selected,
.tabNavigator .ant-menu-horizontal > .ant-menu-item:hover,
.tabNavigator .ant-menu-horizontal > .ant-menu-submenu-active,
.tabNavigator .ant-menu-horizontal > .ant-menu-submenu-open,
.tabNavigator .ant-menu-horizontal > .ant-menu-submenu-selected,
.tabNavigator .ant-menu-horizontal > .ant-menu-submenu:hover {
  border: none;
}
.tabNavigator .collapsedMenuTitle {
  display: none;
}

@media (max-width: 340px) {
  .tabNavigator ul {
    padding-top: 6px !important;
  }
  .tabNavigator ul li {
    padding: 0 8px;
  }
  .tabNavigator ul li i {
    font-size: 9px;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("icomoon.eot?3am48z");
  src: url("icomoon.eot?3am48z#iefix") format("embedded-opentype"),
    url("icomoon.ttf?3am48z") format("truetype"),
    url("icomoon.woff?3am48z") format("woff"),
    url("icomoon.svg?3am48z#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-viubit .path1:before {
  content: "\e9d2";
  color: rgb(234, 220, 255);
}
.icon-viubit .path2:before {
  content: "\e9d3";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path3:before {
  content: "\e9d4";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path4:before {
  content: "\e9d5";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path5:before {
  content: "\e9d6";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path6:before {
  content: "\e9d7";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path7:before {
  content: "\e9d8";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path8:before {
  content: "\e9d9";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path9:before {
  content: "\e9da";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-viubit .path10:before {
  content: "\e9db";
  margin-left: -3.4345703125em;
  color: rgb(88, 68, 175);
}
.icon-paypal .path1:before {
  content: "\e9dc";
  color: rgb(23, 155, 215);
}
.icon-paypal .path2:before {
  content: "\e9dd";
  margin-left: -4.0947265625em;
  color: rgb(23, 155, 215);
}
.icon-paypal .path3:before {
  content: "\e9de";
  margin-left: -4.0947265625em;
  color: rgb(23, 155, 215);
}
.icon-paypal .path4:before {
  content: "\e9df";
  margin-left: -4.0947265625em;
  color: rgb(37, 59, 128);
}
.icon-paypal .path5:before {
  content: "\e9e0";
  margin-left: -4.0947265625em;
  color: rgb(37, 59, 128);
}
.icon-paypal .path6:before {
  content: "\e9e1";
  margin-left: -4.0947265625em;
  color: rgb(37, 59, 128);
}
.icon-paypal .path7:before {
  content: "\e9e2";
  margin-left: -4.0947265625em;
  color: rgb(23, 155, 215);
}
.icon-paypal .path8:before {
  content: "\e9e3";
  margin-left: -4.0947265625em;
  color: rgb(37, 59, 128);
}
.icon-paypal .path9:before {
  content: "\e9e4";
  margin-left: -4.0947265625em;
  color: rgb(34, 45, 101);
}
.icon-credit-or-debit-card .path1:before {
  content: "\e9e5";
  color: rgb(0, 61, 126);
}
.icon-credit-or-debit-card .path2:before {
  content: "\e9e6";
  margin-left: -1.544921875em;
  color: rgb(0, 84, 165);
}
.icon-credit-or-debit-card .path3:before {
  content: "\e9e7";
  margin-left: -1.544921875em;
  color: rgb(0, 61, 126);
}
.icon-credit-or-debit-card .path4:before {
  content: "\e9e8";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path5:before {
  content: "\e9e9";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path6:before {
  content: "\e9ea";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path7:before {
  content: "\e9eb";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path8:before {
  content: "\e9ec";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path9:before {
  content: "\e9ed";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path10:before {
  content: "\e9ee";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path11:before {
  content: "\e9ef";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path12:before {
  content: "\e9f0";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path13:before {
  content: "\e9f1";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path14:before {
  content: "\e9f2";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path15:before {
  content: "\e9f3";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path16:before {
  content: "\e9f4";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path17:before {
  content: "\e9f5";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path18:before {
  content: "\e9f6";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path19:before {
  content: "\e9f7";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path20:before {
  content: "\e9f8";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path21:before {
  content: "\e9f9";
  margin-left: -1.544921875em;
  color: rgb(255, 254, 255);
}
.icon-credit-or-debit-card .path22:before {
  content: "\e9fa";
  margin-left: -1.544921875em;
  color: rgb(0, 84, 165);
}
.icon-credit-or-debit-card .path23:before {
  content: "\e9fb";
  margin-left: -1.544921875em;
  color: rgb(255, 202, 8);
}
.icon-withdraw:before {
  content: "\e9c3";
}
.icon-viubit-history-details:before {
  content: "\e9c4";
}
.icon-transactions-history:before {
  content: "\e9c5";
}
.icon-topup:before {
  content: "\e9c6";
}
.icon-side-menu-theme:before {
  content: "\e9c7";
}
.icon-side-menu-change-password:before {
  content: "\e9c8";
}
.icon-send-gift:before {
  content: "\e9c9";
}
.icon-security-code:before {
  content: "\e9ca";
}
.icon-profile-edit:before {
  content: "\e9cb";
}
.icon-on-demand-video:before {
  content: "\e9cc";
}
.icon-history:before {
  content: "\e9cd";
}
.icon-full-lifetime-access:before {
  content: "\e9ce";
}
.icon-card-details:before {
  content: "\e9cf";
}
.icon-date1:before {
  content: "\e9d0";
}
.icon-creadit-card:before {
  content: "\e9d1";
}
.icon-menu-expand:before {
  content: "\e9be";
}
.icon-date:before {
  content: "\e9bf";
}
.icon-ig:before {
  content: "\e9c0";
}
.icon-twitter1:before {
  content: "\e9c1";
}
.icon-fb:before {
  content: "\e9c2";
}
.icon-director-active:before {
  content: "\e9bc";
}
.icon-director:before {
  content: "\e9bd";
}
.icon-quick-live-active:before {
  content: "\e9ba";
}
.icon-quick-live:before {
  content: "\e9bb";
}
.icon-List-of-Reaction:before {
  content: "\e9b4";
}
.icon-Block-Icon:before {
  content: "\e9b5";
}
.icon-Chat:before {
  content: "\e9b6";
}
.icon-Follow_icon:before {
  content: "\e9b7";
}
.icon-Unblock-Icon:before {
  content: "\e9b8";
}
.icon-Unfollow_icon:before {
  content: "\e9b9";
}
.icon-youtube:before {
  content: "\e9a6";
}
.icon-facebook-circle:before {
  content: "\e9a7";
}
.icon-emoji-animation-icon .path1:before {
  content: "\e993";
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path2:before {
  content: "\e994";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path3:before {
  content: "\e995";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path4:before {
  content: "\e996";
  margin-left: -1.3427734375em;
  color: rgb(234, 220, 255);
}
.icon-emoji-animation-icon .path5:before {
  content: "\e997";
  margin-left: -1.3427734375em;
  color: rgb(234, 220, 255);
}
.icon-emoji-animation-icon .path6:before {
  content: "\e998";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path7:before {
  content: "\e999";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path8:before {
  content: "\e99a";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path9:before {
  content: "\e99b";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path10:before {
  content: "\e99c";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path11:before {
  content: "\e99d";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path12:before {
  content: "\e99e";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-emoji-animation-icon .path13:before {
  content: "\e99f";
  margin-left: -1.3427734375em;
  color: rgb(121, 102, 255);
}
.icon-gif-animation-icon .path1:before {
  content: "\e9a0";
  color: rgb(88, 68, 175);
}
.icon-gif-animation-icon .path2:before {
  content: "\e9a1";
  margin-left: -1.0556640625em;
  color: rgb(234, 220, 255);
}
.icon-gif-animation-icon .path3:before {
  content: "\e9a2";
  margin-left: -1.0556640625em;
  color: rgb(121, 102, 255);
}
.icon-gif-animation-icon .path4:before {
  content: "\e9a3";
  margin-left: -1.0556640625em;
  color: rgb(255, 255, 255);
}
.icon-gif-animation-icon .path5:before {
  content: "\e9a4";
  margin-left: -1.0556640625em;
  color: rgb(255, 255, 255);
}
.icon-gif-animation-icon .path6:before {
  content: "\e9a5";
  margin-left: -1.0556640625em;
  color: rgb(255, 255, 255);
}
.icon-double-like:before {
  content: "\e990";
}
.icon-like:before {
  content: "\e991";
}
.icon-unlike:before {
  content: "\e992";
}
.icon-day:before {
  content: "\e986";
}
.icon-join-to-team:before {
  content: "\e987";
}
.icon-jumplive-active:before {
  content: "\e988";
}
.icon-jumplive:before {
  content: "\e989";
}
.icon-night:before {
  content: "\e98a";
}
.icon-tag .path1:before {
  content: "\e98b";
  color: rgb(247, 247, 247);
}
.icon-tag .path2:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(101, 98, 104);
}
.icon-teamlive-active:before {
  content: "\e98d";
}
.icon-teamlive:before {
  content: "\e98e";
}
.icon-admin-live:before {
  content: "\e98f";
}
.icon-add-camera:before {
  content: "\e90b";
}
.icon-add-guest-picture:before {
  content: "\e90c";
}
.icon-advance-search:before {
  content: "\e90d";
}
.icon-arrow-down:before {
  content: "\e90e";
}
.icon-arrow-up:before {
  content: "\e90f";
}
.icon-back:before {
  content: "\e910";
}
.icon-back-serach:before {
  content: "\e911";
}
.icon-bell:before {
  content: "\e912";
}
.icon-broadcast:before {
  content: "\e913";
}
.icon-broadcast-active:before {
  content: "\e914";
}
.icon-broadcaster-selector:before {
  content: "\e915";
}
.icon-browse:before {
  content: "\e916";
}
.icon-camera:before {
  content: "\e917";
}
.icon-camera-delete:before {
  content: "\e918";
}
.icon-camera-down-arrow:before {
  content: "\e919";
}
.icon-cam-next:before {
  content: "\e91a";
}
.icon-cam-previous:before {
  content: "\e91b";
}
.icon-cams:before {
  content: "\e91c";
}
.icon-cams-landing:before {
  content: "\e91d";
}
.icon-cams-view-page:before {
  content: "\e91e";
}
.icon-cancel:before {
  content: "\e91f";
}
.icon-capture-with-default-camera:before {
  content: "\e920";
}
.icon-change-image:before {
  content: "\e921";
}
.icon-change-password:before {
  content: "\e922";
}
.icon-check-box:before {
  content: "\e923";
}
.icon-check-box-ticks:before {
  content: "\e924";
}
.icon-code:before {
  content: "\e925";
}
.icon-comments:before {
  content: "\e926";
}
.icon-connect-cams:before {
  content: "\e927";
}
.icon-copy:before {
  content: "\e928";
}
.icon-create-an-account:before {
  content: "\e929";
}
.icon-delete:before {
  content: "\e92a";
}
.icon-download:before {
  content: "\e92b";
}
.icon-edit:before {
  content: "\e92c";
}
.icon-effects:before {
  content: "\e92d";
}
.icon-event-location:before {
  content: "\e92e";
}
.icon-expand:before {
  content: "\e92f";
}
.icon-favorites:before {
  content: "\e930";
}
.icon-favorites-active:before {
  content: "\e931";
}
.icon-filter:before {
  content: "\e932";
}
.icon-finish:before {
  content: "\e933";
}
.icon-flash:before {
  content: "\e934";
}
.icon-follow:before {
  content: "\e935";
}
.icon-forgot-password:before {
  content: "\e936";
}
.icon-generate-qr-code:before {
  content: "\e937";
}
.icon-golive:before {
  content: "\e938";
}
.icon-guest:before {
  content: "\e939";
}
.icon-hd-arrow:before {
  content: "\e93a";
}
.icon-hide:before {
  content: "\e93b";
}
.icon-image-close:before {
  content: "\e93c";
}
.icon-invite-friends:before {
  content: "\e93d";
}
.icon-join-me:before {
  content: "\e93e";
}
.icon-leader:before {
  content: "\e93f";
}
.icon-live-section:before {
  content: "\e940";
}
.icon-live-view:before {
  content: "\e941";
}
.icon-location:before {
  content: "\e942";
}
.icon-login:before {
  content: "\e943";
}
.icon-logout:before {
  content: "\e944";
}
.icon-main-search:before {
  content: "\e945";
}
.icon-maximize-screen:before {
  content: "\e946";
}
.icon-menu:before {
  content: "\e947";
}
.icon-menu-about:before {
  content: "\e948";
}
.icon-menu-close:before {
  content: "\e949";
}
.icon-menu-help:before {
  content: "\e94a";
}
.icon-menu-invite-friend-arrow:before {
  content: "\e94b";
}
.icon-menu-login:before {
  content: "\e94c";
}
.icon-menu-my-event:before {
  content: "\e94d";
}
.icon-menu-my-profile:before {
  content: "\e94e";
}
.icon-menu-nortification:before {
  content: "\e94f";
}
.icon-menu-settings:before {
  content: "\e950";
}
.icon-minimize-screen:before {
  content: "\e951";
}
.icon-more-cams:before {
  content: "\e952";
}
.icon-more-details:before {
  content: "\e953";
}
.icon-more-info:before {
  content: "\e954";
}
.icon-my-events:before {
  content: "\e955";
}
.icon-my-events-active:before {
  content: "\e956";
}
.icon-next:before {
  content: "\e957";
}
.icon-nortification:before {
  content: "\e958";
}
.icon-nortification-active:before {
  content: "\e959";
}
.icon-participant:before {
  content: "\e95a";
}
.icon-past-section:before {
  content: "\e95b";
}
.icon-pause:before {
  content: "\e95c";
}
.icon-pincode-time:before {
  content: "\e95d";
}
.icon-play:before {
  content: "\e95e";
}
.icon-posts-more-button:before {
  content: "\e95f";
}
.icon-posts-place:before {
  content: "\e960";
}
.icon-posts-time-date:before {
  content: "\e961";
}
.icon-posts-views:before {
  content: "\e962";
}
.icon-previous:before {
  content: "\e963";
}
.icon-project:before {
  content: "\e964";
}
.icon-reaction-1:before {
  content: "\e965";
}
.icon-reaction-2:before {
  content: "\e966";
}
.icon-reaction-3:before {
  content: "\e967";
}
.icon-reactions-clap:before {
  content: "\e968";
}
.icon-reactions-hand:before {
  content: "\e969";
}
.icon-reactions-heart:before {
  content: "\e96a";
}
.icon-reactions-unlike:before {
  content: "\e96b";
}
.icon-reactions-vomiting:before {
  content: "\e96c";
}
.icon-reactions-wow:before {
  content: "\e96d";
}
.icon-recent-search:before {
  content: "\e96e";
}
.icon-report:before {
  content: "\e96f";
}
.icon-resend:before {
  content: "\e970";
}
.icon-reset-password:before {
  content: "\e971";
}
.icon-resume:before {
  content: "\e972";
}
.icon-schedule:before {
  content: "\e973";
}
.icon-schedule-date:before {
  content: "\e974";
}
.icon-schedule-event-box:before {
  content: "\e975";
}
.icon-schedule-event-tick:before {
  content: "\e976";
}
.icon-schedule-time:before {
  content: "\e977";
}
.icon-search-box:before {
  content: "\e978";
}
.icon-search-close:before {
  content: "\e979";
}
.icon-search-field-arrows:before {
  content: "\e97a";
}
.icon-share:before {
  content: "\e97b";
}
.icon-sign-in-with-apple:before {
  content: "\e97c";
}
.icon-sign-in-with-facebook:before {
  content: "\e97d";
}
.icon-sign-in-with-google:before {
  content: "\e97e";
}
.icon-signup:before {
  content: "\e97f";
}
.icon-sign-up-with-viulive:before {
  content: "\e980";
}
.icon-sign-with-apple:before {
  content: "\e981";
}
.icon-start:before {
  content: "\e982";
}
.icon-submit:before {
  content: "\e983";
}
.icon-successfully-verified:before {
  content: "\e984";
}
.icon-test:before {
  content: "\e985";
}
.icon-test-camera:before {
  content: "\e9a8";
}
.icon-total-view:before {
  content: "\e9a9";
}
.icon-trending-search:before {
  content: "\e9aa";
}
.icon-upcoming-section:before {
  content: "\e9ab";
}
.icon-upcoming-time-date:before {
  content: "\e9ac";
}
.icon-video-settings:before {
  content: "\e9ad";
}
.icon-view-details:before {
  content: "\e9ae";
}
.icon-view-events:before {
  content: "\e9af";
}
.icon-view-events-active:before {
  content: "\e9b0";
}
.icon-volume:before {
  content: "\e9b1";
}
.icon-watch-later:before {
  content: "\e9b2";
}
.icon-yt_icon_pms_mono_dark-eps:before {
  content: "\e9b3";
  color: #f00;
}
.icon-volume-medium:before {
  content: "\e900";
}
.icon-volume-low:before {
  content: "\e901";
}
.icon-mute:before {
  content: "\e902";
}
.icon-zoom-In:before {
  content: "\e903";
}
.icon-zoom-Out:before {
  content: "\e904";
}
.icon-theater-Mode:before {
  content: "\e905";
}
.icon-screen-Share:before {
  content: "\e906";
}
.icon-rotate:before {
  content: "\e907";
}
.icon-replay:before {
  content: "\e908";
}
.icon-refresh:before {
  content: "\e909";
}
.icon-multiple-screen:before {
  content: "\e90a";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
/* .icon-cog:before {
  content: "\ea96";
  
} */
@import "../../../config/styles/colors.scss";
@import "../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

/* Common Styles */
.headerMobile {
  display: flex;
  min-height: 64px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .logo {
    aspect-ratio: 4.63;
    object-fit: contain;
    width: 74px;
  }

  .headerActions {
    display: flex;
    align-items: center;
    gap: 24px;

    .actionIcon {
      aspect-ratio: 1;
      object-fit: contain;
      width: 24px;
    }

    .profileIcon {
      aspect-ratio: 1;
      object-fit: contain;
      width: 32px;
      cursor: pointer;
    }
  }
}

.menu {
  border-radius: 8px;
  align-self: end;
  z-index: 10;
  display: flex;
  margin-top: -8px;
  width: 217px;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  border: 1px solid;
  margin-left: auto;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100vh;

  .authSection {
    display: flex;
    width: 100%;
    flex-direction: column;
    font: 400 14px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;

    .authButton {
      display: flex;
      min-height: 40px;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 12px 16px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 8px;

      &.loginButton {
        margin-top: 0;
      }
    }
  }

  .divider {
    min-height: 1px;
    margin: 16px 0;
    width: 100%;
    border: none;
  }

  .menuItem {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    cursor: pointer;

    .menuItemText {
      font: 400 14px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
    }

    .menuItemIcon {
      aspect-ratio: 1;
      object-fit: contain;
      width: 24px;
    }
  }

  .socialLinks {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  }

  .socialIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 25px;
  }

  .inviteButton {
    border-radius: 4px;
    display: flex;
    margin-top: 16px;
    width: 100%;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 12px 16px;
    font: 400 14px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
    cursor: pointer;

    .inviteIcon {
      width: 11px;
    }
  }

  .copyright {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 8px 0;
    font: 400 10px/1.2 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;

    .companyName {
      margin-top: 4px;
    }
  }
}

/* Dark Theme */
.darkTheme {
  .headerMobile {
    background-color: rgba(36, 31, 58, 1);
    border-bottom: 1px solid rgba(43, 37, 70, 0.7);
  }

  .menu {
    background-color: rgba(36, 31, 58, 1);
    border: 1px solid rgba(35, 35, 35, 0.1);

    .divider {
      background-color: rgba(255, 255, 255, 0.07);
    }

    .authButton {
      color: #fff;

      &.loginButton {
        background-color: rgba(81, 69, 165, 1);
      }

      &.signUpButton {
        background-color: rgba(43, 36, 87, 1);
      }
    }

    .menuItemText {
      color: #fff;
    }

    .inviteButton {
      color: #fff;
      border: 1px solid #fff;
      background-color: rgba(36, 31, 58, 1);

    }

    .copyright {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

/* Light Theme */
.lightTheme {
  .headerMobile {
    background-color: #fff;
    border-bottom: 1px solid rgba(43, 37, 70, 0.1);
  }

  .menu {
    background-color: #fff;
    border: 1px solid rgba(43, 37, 70, 0.1);

    .divider {
      background-color: rgba(0, 0, 0, 0.07);
    }

    .authButton {
      color: #000;

      &.loginButton {
        background-color: rgba(81, 69, 165, 1);
        color: #fff;
      }

      &.signUpButton {
        background-color: rgba(43, 36, 87, 1);
        color: #fff;
      }
    }

    .menuItemText {
      color: #000;
    }

    .inviteButton {
      color: #000;
      border: 1px solid #000;
    }

    .copyright {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

@import "../../../../config/styles/colors.scss";
@import "../../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.forgotPasswordForm {
  .verify-input {
    border: none;
    height: 50px;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 20px;
    &:focus {
      border: 2px solid #6C5ECF;
      box-shadow: none !important;
    }
  }

  button {
    color: $white;
    background-color: $purple-2 !important;
    height: 50px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    // margin-top: 26px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $purple-2 !important;
      color: $white !important;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  &::placeholder {
    color: #3e3952 !important;
  }
  .count-down {
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .count-info {
    margin-right: 30px;
  }

  .ant-modal-header {
    border-bottom: 0;
    box-shadow: none !important;
  }

  .ant-modal-body {
    padding: 25px;
    margin-top: -5px;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }
}

.forgotPasswordForm-mobile {
  .ant-modal {
    padding: 0px !important;
    background-color: red !important;
  }
  .ant-modal-body {
    padding: 24px 16px !important;
    padding-bottom: 0px !important;
  }
  button {
    height: 40px !important;
    font-size: 16px !important;
  }
}

.forgotPasswordVerification {
  margin: 0 auto;
  .resend {
    background-color: $purple-2;
    border-radius: 6px;
    width: 75px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  button {
    width: 100%;
  }

  .styles_react-code-input-container__tpiKG {
    width: 100% !important;
  }
  .styles_react-code-input__CRulA {
    width: 100% !important;
    padding: 0 !important;
    border-radius: 6px !important;

    input {
      border: none;
      height: 60px !important;
      min-width: 51px !important;
    }
  }
}

.verifycode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .styles_react-code-input__CRulA {
    input {
      width: 40px !important;
    }
  }
}
// ===============DARK=================
.dark-modal.ant-modal {
  background-color: $box--dark !important;
  border-radius: 8px !important;
  overflow: hidden;

  h3 {
    color: $white !important;
  }

  h1 {
    color: $white !important;
  }

  .ant-modal-body,
  .ant-modal-header {
    background-color: $box--dark !important;
  }

  .ant-modal-content {
    background-color: $box--dark !important;
    box-shadow: none;
  }

  input {
    background-color: $purple-3 !important;
    color: $white;
  }

  .count-down {
    color: $white;
  }
}

// ===============LIGHT=================
.light-modal.ant-modal {
  background-color: $white !important;
  border-radius: 8px !important;
  overflow: hidden;

  h3 {
    color: $dBlackSec !important;
  }

  h1 {
    color: $dBlackSec !important;
  }

  .ant-modal-body,
  .ant-modal-header {
    background-color: $white !important;
  }

  .ant-modal-content {
    background-color: $white !important;
    box-shadow: none;
  }

  input {
    background-color: $white-secondary;
    color: $dBlackSec;
  }

  .count-down {
    color: $dBlackSec;
  }
}

.verificationContainer {
  border-radius: 8px;
  background-color: rgba(36, 31, 58, 1);
  display: flex;
  max-width: 312px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  // padding: 24px 16px;
  font-family: 'Noto Sans', sans-serif;
}

.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.heading {
  align-self: stretch;
  min-height: 51px;
  width: 100%;
  gap: 10px;
  color: #fff;
}

.instructions {
  align-self: center;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-top: 16px;
}

.formSection {
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.inputWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  justify-content: flex-start;
}

.verificationInput-Mobile {
  align-self: stretch;
  border-radius: 4px;
  border: 2px solid #6c5ecf;
  background-color: #0e0b1e;
  min-height: 32px;
  color: #3e3952;
  text-align: center;
  flex: 1;
  // padding: 0 16px;
}

.iconButton {
  border-radius: 3px;
  background-color: #6c5ecf;
  display: flex;
  min-height: 38px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34px;
  padding: 8px;
  cursor: pointer;
  border:none;

}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
}

.timerSection {
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  gap: 40px 88px;
  font-family: 'Noto Sans', sans-serif;
  justify-content: space-between;
}

.timerText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  align-self: stretch;
  margin: auto 0;
}

.timerValue {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  align-self: stretch;
  margin: auto 0;
}

.confirmButtonWrapper {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.82);
  text-align: center;
  justify-content: flex-start;
  font: 500 16px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.confirmButton {
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #6c5ecf;
  display: flex;
  min-height: 40px;
  width: 100%;
  flex-direction: column;
  padding: 10px 16px;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.verificationContainer,
.verificationCard {
  border-radius: 8px;
  display: flex;
  max-width: 312px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  // padding: 24px 16px;
}

.verificationContainer {
  background-color: rgba(36, 31, 58, 1);
}

.verificationCard {
  background: #fff;
}

.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.heading {
  align-self: stretch;
  min-height: 51px;
  width: 100%;
  gap: 10px;
  font: 600 18px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.verificationContainer .heading {
  color: #fff;
}

.verificationCard .heading {
  color: #000;
}

.instructions {
  align-self: center;
  text-align: center;
  margin-top: 16px;
  font: 400 10px/12px Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.verificationContainer .instructions {
  color: rgba(255, 255, 255, 0.6);
}

.verificationCard .instructions {
  color: rgba(0, 0, 0, 0.6);
}

.formSection {
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.inputWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  justify-content: flex-start;
}

.verificationInput-Mobile {
  align-self: stretch;
  border-radius: 4px;
  border: 2px solid #6c5ecf;
  min-height: 32px;
  text-align: center;
  flex: 1;
  // padding: 0 16px;
  font: 400 12px/26px Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.verificationContainer .verificationInput-Mobile {
  background-color: #0e0b1e;
  color: #3e3952;
}

.verificationCard .verificationInput-Mobile {
  background: #f0f0f0;
  color: #d1d1d1;
}

.iconButton {
  border-radius: 3px;
  background-color: #6c5ecf;
  display: flex;
  min-height: 38px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34px;
  padding: 8px;
  cursor: pointer;
  border: none;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
}

.timerSection {
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  gap: 40px 88px;
  font-family: Noto Sans, sans-serif;
  justify-content: space-between;
}

.timerText {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  align-self: stretch;
  margin: auto 0;
}

.verificationContainer .timerText {
  color: rgba(255, 255, 255, 0.6);
}

.verificationCard .timerText {
  color: rgba(0, 0, 0, 0.6);
}

.timerValue {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  align-self: stretch;
  margin: auto 0;
}

.verificationContainer .timerValue {
  color: #fff;
}

.verificationCard .timerValue {
  color: #000;
}

.confirmButtonWrapper {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  font: 500 16px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.confirmButton {
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  display: flex;
  min-height: 40px;
  width: 100%;
  flex-direction: column;
  padding: 10px 16px;
  cursor: pointer;
  border: none;
}

.verificationContainer .confirmButton {
  background: #6c5ecf;
  color: rgba(255, 255, 255, 0.82);
}

.verificationCard .confirmButton {
  background: #5145a5;
  color: #fff;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 769px) {
  .verificationInput input {
      width: 39px !important;
  }
}

.verificationInput-Mobile input {
  height: 35px !important;
  width: 39px !important;
  border-color: #777777;
}

@media screen and (max-width: 768px) {
  .styles_react-code-input__CRulA input {
      width: 39px !important;
  }
}

@media screen and (max-width: 768px) {
  .styles_react-code-input__CRulA input {
      max-width: 38px !important;
      border: none !important;
      border-right: none !important;
  }
}

.styles_react-code-input__CRulA > input:last-child {
  /* border-right: solid 1px #a8adb7; */
  border-right: none !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.forgotPasswordVerification .styles_react-code-input__CRulA input {
  border: none !important;
  height: 60px !important;
  min-width: 57px !important;
  border-right: none !important;
}
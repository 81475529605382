@import "config/styles/colors.scss";
@import "config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@import "./config/styles/theme/theme.scss";
@import "./config/styles/colors.scss";
@import "./config/styles/measurement.scss";

@import url("./view/layout/fonts/fonts.css");

$font-path: "./view/layout/fonts/";

@font-face {
  font-family: "viulive";
  src: url("#{$font-path}/icomoon.eot?1rffm3");
  src: url("#{$font-path}/icomoon.eot?1rffm3#iefix") format("embedded-opentype"),
    url("#{$font-path}/icomoon.ttf?1rffm3") format("truetype"),
    url("#{$font-path}/icomoon.woff?1rffm3") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hurme";
  src: url("#{$font-path}/HurmeGeometric.eot?1rffm3");
  src: url("#{$font-path}/HurmeGeometric.eot?1rffm3#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/HurmeGeometric.ttf?1rffm3") format("truetype"),
    url("#{$font-path}/HurmeGeometric.woff?1rffm3") format("woff"),
    url("#{$font-path}/HurmeGeometric.woff2?1rffm3") format("woff2");

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sf";
  src: url("#{$font-path}/sf.ttf?1rffm3") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("#{$font-path}/NotoSans.ttf?1rffm3") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $darkGray transparent;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $darkGray;
    border-radius: $radius + 16;
  }
}

.secondFont {
  font: {
    family: "hurme" !important;
    style: normal;
    weight: normal;
    variant: normal;
  }
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: none;
  font: {
    family: "viulive" !important;
    style: normal;
    weight: normal;
    variant: normal;
  }

  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* variables ============================ */
:root {
  --white-color: #fff;
  --gray-color: #f7f7f7;
  --light-gray-color: #f5f5f5;
  --dark-gray-color: #888888;
  --purple-color: #5c44af;
  --black-color: #333;
  --green-color: #2cc483;
  --yellow-color: #ffce00;
  --border-radius: 4px;
  --red-color: #e64a19;
  --blue-color: #4267b2;
}

/* end of variables ========================= */
html {
  scroll-behavior: smooth;
  --antd-wave-shadow-color: $purple !important;
  outline: 0 !important;
}
html ::selection {
  background: $purple !important;
}
:focus {
  outline: 0 !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "sf" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  // @media (min-width: 1366px) {
  //   min-height: 102vh;
  // }
  @include themed() {
    background-color: t($bg);
  }
}
p {
  @include themed() {
    color: t($textColor);
  }
}
a {
  @include themed() {
    color: t($textColor);
  }
  &:hover {
    color: $purple;
  }
}
h3 {
  @include themed() {
    color: t($headingColor);
  }
}
main {
  @include themed() {
    background: t($bg);
  }
  overflow-x: hidden;
  padding: 10px 15px !important;
  margin: 63px auto;
  max-width: 1600px;
  width: 100%;
}
.ant-layout {
  @include themed() {
    background: t($bg);
  }
}

.purpleBtn {
  background: $purple !important;
  color: $white !important;
  border-radius: $radius;
  @include themed() {
    border-color: t($boxBorderColor) !important;
  }
  i {
    color: $white;
    margin-right: 5px;
  }
  &:focus,
  &:active,
  &:hover {
    color: $white !important;

    border-color: $purple !important;
    i {
      color: $purple !important;
    }
  }
}
.redBtn {
  background: $red;
  color: $white;
  border-radius: $radius;
  i {
    color: $red;
  }
  &:focus,
  &:active,
  &:hover {
    color: $red !important;
    border-color: $red !important;
    i {
      color: $red;
    }
  }
}

._redBtn {
  background: $red !important;
  color: $white !important;
  border-radius: $radius;
  i {
    color: $white;
  }
  &:focus,
  &:active,
  &:hover {
    background: $red !important;
    color: $white !important;
    border-color: $red !important;
    i {
      color: $white !important;
    }
  }
}

.whiteBtn {
  border-radius: $radius;
  margin-bottom: 4px;
  border-color: $darkGray;
  i {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
  &:hover,
  &:focus {
    border-color: $purple;
    color: $purple;
  }
  .ant-select-selection {
    &:hover,
    &:focus {
      border-color: $purple;
      color: $purple;
    }
  }
  .ant-time-picker-input {
    &:hover,
    &:focus {
      border-color: $purple;
      color: $purple;
    }
  }
}
.backBtn {
  @include themed() {
    color: t($subTextColor) !important;
  }
  margin-bottom: 10px;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
}

.transparentBtn {
  border-radius: $radius;
  margin-bottom: 4px;
  border-color: $darkGray;
  background: transparent !important;
  @include themed() {
    color: t($subTextColor) !important;
    border-color: t($subTextColor);
  }
  &:hover,
  &:focus {
    background: transparent !important;
    border-color: $purple !important;
    color: $purple !important;
  }
  i {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}
.noBorderBtn {
  border: none;
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: lighten($color: $black, $amount: 10%);
  height: 20px;
}
.noBorder {
  border: none !important;
}

.circleBtn {
  width: 70px;
  height: 70px;
  white-space: initial;
  border-radius: 50%;
  padding: 5px;
  font-size: 13px;
  &.liveColor {
    background: $purple;
    color: $white;
    border-color: darken($color: $darkGray, $amount: 15%);
  }
  &.scheduleColor {
    background: $gray;
    color: $darkGray;
    border-color: $darkGray;
  }
}

body .displayBlock {
  display: block;
}
body .textCenter {
  text-align: center;
}
body .textRight {
  text-align: right;
}
.ant-row.ant-form-item {
  margin-bottom: 0;
}
.MSD-Label {
  color: $black !important;
}
.MSD-DatePicker {
  width: 100% !important;
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
.ant-dropdown-menu-item {
  padding: 0 !important;
  span,
  a {
    padding: 5px 9px !important;
    margin: 0 !important;
    display: block !important;
  }
}

.verificationInput {
  margin: 0 auto;
  input {
    height: 35px !important;
    width: 50px !important;
    border-color: darken($color: $darkGray, $amount: 20%);
    &:focus {
      border-color: $purple !important;
    }
  }
  .codeError {
    input {
      border-color: $red;
    }
  }
}
.ant-list-split.noMargin {
  margin-top: 0 !important;
}
.ant-skeleton.ant-skeleton-with-avatar.ant-skeleton-active {
  margin-top: 25px;
}
.ant-modal-body {
  padding: 25px !important;
}
.tooltipInfo {
  // cursor: pointer;
  box-shadow: none;
  &:after {
    content: "!";
    background: $purple;
    color: $white;
    margin-left: 6px;
    padding: 0 6px;
    border-radius: 50%;
    font-size: 13px;
  }
}
.skeleton {
  margin-top: 20px;
  &:hover,
  &:active {
    box-shadow: none !important;
    border-color: $purple !important;
  }
}
.ant-modal-mask {
  background: #000000B2 !important;
}
.blockBtn {
  display: block;
  width: 100%;
  margin-top: 10px;
}
.ant-checkbox-inner {
  background-color: transparent !important;
  border-color: $darkGray !important;
  border-radius: $radius;
}
.ant-checkbox-inner:after {
  border: 2px solid $purple !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $purple !important;
}
.ant-carousel .slick-next:before {
  content: ">";
}
.ant-carousel .slick-prev:before {
  content: "<";
}
.ant-form-item-children {
  display: block;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 150px;
  display: inline-block;
  top: 5px;
  z-index: 2;
}
.input-range__label-container {
  display: none !important;
}
.input-range__track {
  background: $darkGray !important;
}
.input-range__track--active {
  background: $purple !important;
}
.input-range__slider {
  opacity: 0 !important;
  background: $purple !important;
  border-color: $purple !important;
  transition: all 0.1s !important;
  margin-top: -0.7rem !important;
}
.input-range:hover .input-range__slider {
  opacity: 1 !important;
}
.input-range__track {
  height: 0.4rem !important;
  border-radius: 0 !important;
}
.ant-list-split .ant-list-item {
  padding: 5px 0 0 !important;
}
.ant-dropdown .ant-dropdown-menu {
  box-shadow: 2px 0px 3px rgba($black, 0.1) !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item:hover a {
  background: transparent !important;
  color: $purple;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date {
  background: $purple;
  color: $white !important;
}
.ant-calendar .ant-calendar-ok-btn {
  border-color: $purple;
  background-color: $purple;
  color: $white;
}
.ant-calendar-today .ant-calendar-date {
  border-color: $purple;
  color: $purple;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  background: $purple;
}
.ant-calendar .ant-calendar-ok-btn:focus,
.ant-calendar .ant-calendar-ok-btn:hover {
  border-color: $purple;
  background-color: $purple;
}
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover {
  background-color: $purple;
}
.ant-calendar-date:active {
  background-color: $purple;
}
.ant-input:focus,
.ant-input:hover {
  border-color: $purple;
  box-shadow: none;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus {
  border-color: $purple;
  box-shadow: none;
}

.ant-comment-content-author > a,
.ant-comment-content-author > span {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

body .whiteBox {
  background: $white;
  /* margin: 10px 0; */
  padding: 5px;
  /* border-radius: 4px;
    box-shadow:0 0 1px rgba(0,0,0,0.2)  */
}

.ant-popover-inner-content {
  padding: 0px !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
.ant-empty-image {
  @include themed() {
    color: t($textColor);
  }
}

.MSD-Loader {
  @include themed() {
    background: rgba(t($bg), 0.5);
  }
}
.dark-notification {
  background: $bg--dark + 30 !important;
  .ant-notification-notice-message,
  .ant-notification-close-x,
  .ant-notification-notice-description {
    color: $white !important;
  }
}
.dark-modal-throw {
  .ant-modal-content {
    background: $bg--dark + 30 !important;
  }
  .ant-modal-confirm-title,
  .ant-modal-confirm-content {
    color: $white !important;
  }
  .ant-btn {
    background: transparent !important;
    border-color: $gray - 100 !important;
    color: $white !important;
  }
  .ant-btn-primary {
    background: $purple !important;
    border: none !important;
  }
}

.offlineMessage {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    font-size: 25px;
  }
  span {
    font-size: 20px;
    @include themed() {
      color: t($subTextColor);
      border: 1px solid t($boxBorderColor);
    }
    cursor: pointer;
    padding: 5px 15px;
  }
}
/* ==================== Media */

@media (max-width: 769px) {
  main {
    padding: 3px 0 0 !important;
    margin-top: 59px !important;
  }
  body .ant-list-split {
    margin-top: 20px !important;
  }
  .verificationInput {
    width: 300px !important;
  }
  .verificationInput input {
    width: 50px !important;
  }
  .transparentBtn {
    height: 32px !important;
  }
  .skeleton {
    margin-top: 0;
  }
  *::-webkit-scrollbar {
    height: 6px;
  }
}

@media (max-width: 790px) and (min-width: 760px) {
  .ant-drawer-body {
    max-width: 500px !important;
    margin: 0 auto !important;
  }
}
.ant-tooltip-arrow {
  display: none !important;
}

.vume-loader {
  position: relative;
  &--user {
    position: relative;
  }
  &__userinfo {
    display: block;
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.search-min-messgage {
  color: $red;
  font-size: 12px;
  padding: 8px;
}

.MSD-Crop {
  overflow: visible;
  position: relative;
  canvas {
    max-width: 90% !important;
  }
}
.cropConfig span {
  position: absolute;
  height: 90%;
  right: 4px;
  top: 0;
  z-index: 9;
  bottom: 0;
}

.vume-container .vume-card-top__title {
  width: 100%;
}

@import "../../../config/styles/colors.scss";
@import "../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.menuAvatar {
  margin-bottom: 1em;
  figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 5px auto;
    z-index: 2;
    position: relative;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  &.loginnedAvatar {
    // position: absolute;
    // top: -100px;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    figure {
      width: 50px;
      height: 50px;
    }
  }
}

.avatarUserName {
  @include themed() {
    color: t($headingColor);
  }
  font-weight: bold;
  text-align: center;
  position: relative;
  z-index: 2;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
}

.copyright {
  font-size: 11px;
  padding-top: 1px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;

  .footerLinks {
    font-size: 10px !important;
    @include themed() {
      color: t($textColor);
    }
    margin-right: 6px;
    margin-left: 6px;
    font-weight: 400;
    text-decoration: none;
    display: inline-flex; /* Use inline-flex to allow flexbox alignment */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.3s, color 0.3s, border-radius 0.3s,
      padding 0.3s;
    border-radius: 5px;
    padding: 0 !important; /* Remove padding in the default state */
    height: 32px; /* Set a fixed height */
    &:hover {
      @include themed() {
        background-color: $purple;
        color: white !important;
        border-radius: 5px;
        padding: 2px 10px !important; /* Add padding to extend the background color */
        height: 32px; /* Maintain the fixed height */
      }
    }
  }
}

.ant-btn.footerLinks.ant-btn-link {
  padding: 0;
  padding: 0 !important; /* Remove padding in the default state */
  &:hover {
    @include themed() {
      background-color: $purple;
      color: white;
      border-radius: 5px;
      padding: 2px 10px !important; /* Add padding to extend the background color */
      height: 32px; /* Maintain the fixed height */
    }
  }
}

.ant-btn.footerLinks.ant-btn-link {
  &:focus {
    @include themed() {
      padding: 2px 10px !important; /* Add padding to extend the background color */
    }
  }
}

.dark-theme .ant-btn.footerLinks.ant-btn-link {
  @include themed() {
    &:focus {
      background-color: $purple !important; /* Replace with your dark theme background color */
      color: t($textColor) !important; /* Replace with your light text color */
    }
  }
}

.HamburgerMenu {
  @include themed() {
    color: t($textColor) !important;
  }
}

.avatarBackground {
  width: 100%;
  // z-index: 1;
}

.sidebarDrawer {
  .ant-drawer-body {
    padding-top: 45px;
  }
  .drawerBack {
    text-align: right;
    cursor: pointer;
    margin-bottom: 30px;
    i {
      font-size: 10px;
      margin-left: 5px;
    }
  }
  &.ant-drawer-open {
    left: 0;
    z-index: 100;
    .ant-drawer-content-wrapper {
      right: 201px;
    }
    .ant-drawer-mask {
      left: auto;
      width: calc(100% - 201px);
    }
  }
  &.ant-drawer-right {
    &.ant-drawer-open {
      .ant-drawer-content-wrapper {
        box-shadow: none;
      }
    }
  }
}

.ant-drawer {
  .ant-drawer-content {
    overflow-y: auto;
  }
}
.sider {
  &.ant-layout-sider-collapsed {
    .menuAvatar {
      margin-bottom: initial;
      p {
        font-size: 11px;
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
  }
}

.avatarBackground {
  width: 100%;
}
.downloadAppSidebar {
  margin: 0 0;
  text-align: center;
}
.downloadAppSidebar img {
  width: 80px;
  height: 27px;
  object-fit: contain;
  // border-radius: 7px;
}
.downloadAppSidebar a {
  padding: 5px;
}
.socialBox {
  @include themed() {
    // border-top: 1px solid t($lineColor);
  }
  a {
    @include themed() {
      color: t($textColor) !important;
    }
  }
}
.copyrightText {
  font-size: 10px;
  @include themed() {
    color: t($textColor) !important;
  }
}
.darkDrawer {
  .ant-drawer-content {
    background: $bg--dark;
  }
  .ant-drawer-header-no-title button {
    color: $white;
  }
  .rrs__options {
    background: $bg--dark !important;
    color: $subText--dark !important;
  }
  .rrs__option.rrs__option--next-selection {
    background: transparent !important;
  }
  .rrs__label {
    border-color: $boxBorder--dark !important;
    color: $subText--dark !important;
  }
  .rrs__button {
    border-color: $boxBorder--dark !important;
  }
}

.inviteFriendBtn {
  padding: 10px 25px !important;
  margin-bottom: 10px;
  button {
    height: 30px !important;
  }
}
.settingBtn {
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  margin-bottom: -10px;
  @include themed() {
    color: t($textColor);
    border: 1px solid t($boxBorderColor);
  }
  i {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}
@media (max-width: 1200px) {
  .menuAvatar.mobileSidebar {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .menuAvatar.mobileSidebar figure {
    width: 60px;
    height: 60px;
  }
  .avatarBackground.mobileSidebar {
    width: 250px !important;
  }
}
.mobileSidebar {
  .following {
    margin-top: 50px;
  }
  i {
    @include themed() {
      color: t($textColor) !important;
    }
  }
}

.ant-layout-sider-children {
  &.flex-sider {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
  }
}

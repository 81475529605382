@import "../../config/styles/colors.scss";
@import "../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@import "../../config/styles//theme/theme.scss";

.sider {
  @include themed() {
    background: t($boxColor);
  }
  text-align: center;
  overflow-x: hidden;
  height: 100vh;
  position: fixed !important;
  left: 0;
  padding-top: 10px;
  z-index: 120;
  overflow-y: auto;
  .trigger {
    float: left;
    margin-left: 20px;
    cursor: pointer;
    @include themed() {
      color: t($textColor);
    }
    font-size: 16px;
    position: relative;
    z-index: 10;
  }
  &.ant-layout-sider-collapsed {
    .trigger {
      font-size: 16px;
      margin-bottom: 20px;
      display: block;
      float: none;
      margin-left: 0;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  // &:hover {
  //   &::-webkit-scrollbar {
  //     display: block;
  //   }
  // }
}

.downloadApp {
  position: fixed;
  bottom: 59px;
  z-index: 999;
  width: 100%;
  @include themed() {
    background: t($boxColor);
    border-bottom: 1px solid t($boxBorderColor);
    border-top: 1px solid t($boxBorderColor);
  }
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  padding: 19px;
  a {
    @include themed() {
      color: t($textColor);
    }
  }
  img {
    width: 20px;
    margin-right: 5px;
  }
  span {
    position: absolute;
    right: 20px;
    font-size: 20px;
    top: 15px;
    @include themed() {
      color: t($textColor);
    }
  }
}

.header {
  @include themed() {
    background-color: t($boxColor) !important;
  }
  padding: 0 10px !important;
  position: fixed;
  height: 65px !important;
  width: 100%;
  z-index: 90;
  .mobileSearchIcon {
    font-size: 22px;
    @include themed() {
      color: t($textColor);
    }
  }
}

@media (min-width: 760px) {
  section.ant-layout.ant-layout-has-sider {
    transition: all 0.2s;
    margin-left: 190px;
  }
}

@media (max-width: 740px) {
  .header {
    // padding: 0 10px !important;
    padding: 0px !important;
    height: 65px !important;
  }
  .sider {
    display: none;
  }
}

.vume-header {
  z-index: 999;
  left: 0;
  &__logo {
    display: flex;
    align-items: center;
  }
  .viu-sideBtn {
    margin-right: 1rem;
  }
}
.ant-message {
  z-index: 999999;
}
.viu-sideBtn {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 5px;
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 3px;
    @include themed() {
      background-color: t($textColor);
    }
    width: 100%;
    border-radius: 1rem;
    transition: all 0.4s ease-in-out;
  }
  .span--one {
    top: 5px;
  }
  .span--two {
    top: 16px;
  }
  .span--three {
    bottom: 0px;
    left: 0;
    transition: all 0.4s ease-in-out;
  }
  &.closed {
    .span--three {
      width: 50%;
      transition: all 0.4s ease-in-out;
    }
  }
}
.vume-sidebar {
  padding-top: 60px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  .vume-setting-btn {
    padding: 5px 30px;
    width: 114px !important;
    height: 28px !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.vume-container {
  max-width: 1100px;
  margin: 0 auto;
  @include themed() {
    color: t($textColor);
  }
}
.vume-landing-ad {
  position: relative;
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-close {
    width: 25px;
    height: 25px;
    background-color: #ff0505;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    top: 0;
    right: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }
  .ant-modal-body {
    padding: 0 !important;
    border-radius: 10px;
    overflow: hidden;
    img {
      margin-top: 0 !important;
    }
  }
}

.sidebar-button {
  cursor: pointer;
  width: 50px;
  height: 40px;
  background-color: #FFFFFF;
  position: fixed;
  top: 90px;
  z-index: 1000;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.sidebar-button.dark-theme {
  background-color: #241F3A;
  display: none;
}


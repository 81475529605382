@import "../../../../config/styles/colors.scss";
@import "../../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.desktopMenu {
  text-align: left;
  border: none !important;
  li {
    margin-bottom: 15px !important;
    @include themed() {
      color: t($textColor);
    }
    &:hover {
      div {
        @include themed() {
          color: t($headingColor);
        }
      }
    }
  }
  .activeMenu {
    color:$purple !important;
    background-color: transparent !important;
    @include themed() {
      // background-color: t($activeSideBarBackgroundColor) !important;
    }
    i,
    span,
    div {
      color:$purple !important;
      @include themed() {
        // color: t($activeSideBarColor) !important;
      }
    }
  }
  &.ant-menu-inline-collapsed {
    li {
      margin-bottom: 25px !important;
    }
    .activeMenu {
      background-color: transparent !important;
    }
    .ant-menu-item {
      div {
        span {
          font-size: 11px;
        }
      }
    }
  }
  &.ant-menu-item-selected {
    background: transparent !important;
    &:after {
      display: none;
    }
  }
  .ant-menu-item {
    &:after {
      display: none;
    }
  }
}

.tabNavigator {
  .desktopMenu {
    .activeMenu {
      background-color: transparent !important;
    }
  }
}
.collapsedMenuTitle {
  top: -23px;
  position: relative;
  font-size: 11px;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-menu {
  @include themed() {
    background: t($boxColor);
  }

  padding-top: 5px !important;

  i {
    @include themed() {
      color: t($textColor);
    }
    display: block;
    font-size: 20px;
    margin-top: 1px;
  }

  &.ant-menu-inline {
    i {
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 3px;
    }
  }
  &.desktopMenu {
    &.ant-menu-inline {
      li {
        height: 45px;
        line-height: 45px;
        padding: 0 !important;
        div {
          padding-left: 24px;
        }
      }
    }
  }
}

.ant-menu-item {
  &:active {
    background: transparent;
  }
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:not(.activeMenu) {
  background-color: transparent !important;
}

@media (min-width: 740px) {
  body .ant-menu-inline-collapsed .ant-menu-inline > .ant-menu-item,
  body .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  body .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  body .ant-menu-vertical-right > .ant-menu-item,
  body .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  body .ant-menu-vertical > .ant-menu-item,
  body .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50px !important;
    text-align: center;
    padding: 0 !important;
  }
}

@media (max-width: 769px) {
  .ant-menu.desktopMenu {
    background: transparent;
    border: none;
  }
  .desktopMenu .ant-menu-item-selected {
    background: transparent !important;
  }
  .desktopMenu .ant-menu-item:after {
    display: none;
  }
}

.icon-simple {
  margin-right: 15px;
  @media screen and (max-width: 911px) {
    display: block;
    margin-left: 15px;
  }
}


.icon-collapsed {
  margin-bottom: 5px;
  @media screen and (max-width: 911px) {
    display: block;
    margin-bottom: 0;
  }
}


.switch_director {
  background-color: #FFFFFFD1;
  width: 100%;
  border-radius: 3px;
  padding: 8px;
  margin-top: 15px;
  color: #171717;
  font-size:14px;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  

  span {
    margin-left: 8px;
  }
}
@import "../../../config/styles/colors.scss";
@import "../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

  button {
    border-radius: 6px;
    border: none;
    outline: none;
    min-width: 90px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
  }

  .auth-sigin {
    margin-left: -20px;
    margin-right: 10px;
    @include themed() {
      background-color: t($bg-secondary);
      color: t($textColor-2);
    }
  }

  .auth-signup {
    background-color: $purple-2;
    color: $white;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

// ====DARK
.signin-modal-dark.ant-modal,
.signup-modal-dark.ant-modal {
  background-color: $box--dark !important;
  border-radius: 8px !important;
  overflow: hidden;
  padding: 0;

  .ant-modal-body,
  .ant-modal-header {
    background-color: $box--dark !important;
  }

  .ant-modal-content {
    background-color: $box--dark !important;
    box-shadow: none;
  }

  .form-title {
    color: $white;
  }

  .auth-input,
  .ant-input {
    background: $purple-3 !important;
    color: $white !important;

    &:focus {
      background: $purple-3 !important;
      color: $white !important;
      border: 2px solid #6C5ECF;
      box-shadow: none;
      border-right-width: 2px !important;
    }
  }
  .checkbox-contanier p {
    color: $white !important;
  }

  .signup-redirect {
    span {
      color: $white !important;
    }
  }

  .remember-me {
    color: $white !important;
    .ant-checkbox-inner {
      border: 1px solid $white !important;
      &:after {
        background-color: $purple-2;
      }
    }
    a {
      color: $white !important;
    }
  }

  .termsContainer,
  .registerTermsContainer {
    color: $white !important;
  }

  .count-down {
    color: $white;
  }

  .styles_react-code-input__CRulA input {
    background-color: $purple-3 !important;
    color: $white;
  }
}

// ====LIGHT
.signin-modal-light.ant-modal,
.signup-modal-light.ant-modal {
  background-color: $white !important;
  border-radius: 8px !important;
  overflow: hidden;
  padding: 0;

  .ant-modal-body,
  .ant-modal-header {
    background-color: $white !important;
  }

  .ant-modal-content {
    background-color: $white !important;
    box-shadow: none;
  }

  .form-title {
    color: $dBlackSec;
  }

  .auth-input,
  .ant-input {
    background: $white-secondary !important;
    color: $dBlackSec !important;

    &:focus {
      background: $white-secondary !important;
      color: $dBlackSec !important;
      border: 2px solid #6C5ECF;
      box-shadow: none;
      border-right-width: 2px !important;
    }
  }

  .checkbox-contanier p {
    color: $dBlackSec !important;
  }

  .signup-redirect {
    span {
      color: $dBlackSec !important;
    }
  }

  .remember-me {
    color: $dBlackSec !important;
    .ant-checkbox-inner {
      border: 1px solid $dBlackSec !important;
      &:after {
        background-color: $purple-2;
      }
    }
    a {
      color: $dBlackSec !important;
    }
  }

  .termsContainer,
  .registerTermsContainer {
    color: $dBlackSec !important;
  }

  .count-down {
    color: $dBlackSec;
  }

  .styles_react-code-input__CRulA input {
    background-color: $white-secondary;
    color: $dBlackSec;
  }
}

.auth-modal {
  .ant-modal-header {
    border-bottom: 0;
    box-shadow: none !important;
  }
  .ant-modal-body {
    padding: 45px !important;
    margin-top: -5px;
  }
  .ant-modal-close-x {
    display: none;
  }

  .safety_section_login_google {
    background: transparent !important;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-shadow: none !important;
    div {
      display: none;
    }
    svg {
      display: none;
    }
    span {
      padding: 0 !important;
    }
  }
}

.form-title {
  text-align: center;
  font-size: 27px;
  list-style: 30px;
  font-weight: 700;
  margin-top: 15px;
  letter-spacing: 0.5px;
}

.form-container {
  display: flex;
  flex-direction: column;

  .auth-input,
  button {
    border-radius: 6px;
    border: none;
    outline: none;
    margin-top: 24px;
    height: 50px;
    padding: 10px;
  }

  button {
    background-color: $purple-2;
    color: $white;
    font-weight: 700;
    font-size: 20px;
    list-style: 22px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
}

.social-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;

  img {
    width: 40px;
    height: 40px;
    margin: 0 15px;
    cursor: pointer;
  }
}

.checkbox-contanier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0 0;

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    cursor: pointer;
  }
}

.signup-redirect {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #71717a;
  text-align: center;

  span {
    cursor: pointer;
    font-weight: 600;
  }
}

.remember-me {
  .ant-checkbox-inner {
    overflow: hidden;
    &:after {
      width: 100%;
      height: 100%;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      transform: rotate(0) scale(1) translate(0, 0) !important;
    }
  }
}

.menuContainer {
  border-radius: 8px;
  background-color: rgba(36, 31, 58, 1);
  align-self: end;
  z-index: 10;
  display: flex;
  margin-top: -8px;
  width: 217px;
  flex-direction: column;
  overflow: hidden;
  color: #fff;
  padding: 16px;
  font: 400 14px/1 Noto Sans, -apple-system, Roboto, Helvetica, sans-serif;
  border: 1px solid rgba(43, 37, 70, 1);
  float: inline-end;
}

.authSection {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.loginButton {
  border-radius: 4px;
  background-color: rgba(21, 17, 42, 1);
  width: 100%;
  padding: 12px 16px;
  border: 1px;
}

.signUpButton {
  border-radius: 4px;
  background-color: rgba(81, 69, 165, 1);
  margin-top: 16px;
  width: 100%;
  padding: 12px 16px;
  border: 1px;
}

.divider {
  background-color: rgba(255, 255, 255, 0.07);
  height: 1px;
  margin: 10px 0;
  width: 100%;
  border: 0;
}

.menuItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.menuText {
  font-size: 14px;
}

.menuIcon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 24px;
}

.auth-modal-mobile {
  .ant-modal-body {
    width: 312px;
    padding: 24px 16px !important;
  }
}

.styles_react-code-input__CRulA input {
  border:none !important;
  border-right: none !important;
}

.auth-modal .signup-modal-dark.ant-modal, .signup-modal-light.ant-modal{
    .ant-modal-body {
      padding: 30px !important;
      margin-top: -5px;
    }
}

.auth-modal-mobile.ant-modal {
  .ant-modal-body {
    padding: 16px !important;
    margin-top: -5px;
  } 
}

.auth-modal-mobile .signup-modal-dark.ant-modal {
  .ant-modal-body {
    padding: 12px !important;
    // padding: 24px 16px !important;
    margin-top: -5px;
  } 
}

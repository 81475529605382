@import "../../../../../config/styles/colors.scss";
@import "../../../../../config/styles/theme/themeVariables.scss";

$themes: (
  light: (
    bg: $bg--light,
    bg-secondary:$white-secondary,
    headingColor: $heading--light,
    textColor: $text--light,
    subTextColor: $subText--light,
    boxColor: $box--light,
    lineColor: $line--light,
    boxBorderColor: $boxBorder--light,
    activeSideBarColor: $purple,
    activeSideBarBackgroundColor: $gray,
    placeholder:$placeholder--light,
    textColor-2:$dBlackSec,
  ),
  dark: (
    bg: $bg--dark,
    bg-secondary:$bg--dark,
    headingColor: $heading--dark,
    textColor: $text--dark,
    subTextColor: $subText--dark,
    boxColor: $box--dark,
    lineColor: $line--dark,
    boxBorderColor: $boxBorder--dark,
    activeSideBarColor: $white,
    activeSideBarBackgroundColor: $purple-light,
    placeholder:$placeholder--dark,
    textColor-2:$white,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// Theme based
$bg--light: #f5f5f5;
$bg--dark: #15112a;

$heading--dark: #fff;
$text--dark: #eee;
$subText--dark: #aaa;
$heading--light: #000;
$text--light: #333;
$subText--light: #888;
$placeholder--light:#B3B3B3;
$placeholder--dark: #3E3952;

$box--light: #fff;
$boxBorder--light: #ddd;
$box--dark: #241f3a;
$boxBorder--dark: #646375;

$line--light: #eee;
$line--dark: #616161;

// simple colors
$purple: #5c44af;
$purple-2:#5145A5;
$purple-3:#0E0B1E;
$purple-light: #38334e;
$categoryPurple: #9d8fff;
$white: #fff;
$gray: #f7f7f7;
$lightGray: #f5f5f5;
$darkGray: #aaa;
$black: #333;
$green: #2cc483;
$yellow: #ffce00;
$red: #e64a19;
$blue: #4267b2;
$white-secondary:#F0F0F0;
$modal-bg:#030206;


//director's colors
$dBlackFirst: #181818;
$dBlackSec: #171717;
$dBlackThird: #232323;

$dgray: rgba(18, 18, 18, 0.6);
$dgraySec: rgba(200, 193, 193, 0.8);
$dgrayThird: rgba(59, 59, 59, 0.7);
$dgrayFourth: rgba(18, 18, 18, 0.3);
$dgrayFifth: rgba(114, 109, 109, 0.1);
$dgraySixth: rgba(114, 109, 109, 0.5);

$dwhite: rgba(255, 255, 255, 0.82);

$dblack: rgba(35, 35, 35, 1);
$dblackSec: rgba(23, 23, 23, 1);

$dprimary: rgba(108, 94, 207, 1);
$dprimaryLight: rgba(108, 94, 207, 0.6);

$radius: 4px;

// ----------------------------------------------------------------------
// placeholder text color
// example: @include placeholder-color(#333);
// ----------------------------------------------------------------------
@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ----------------------------------------------------------------------
// Transitions
// example: @include single-transition(background, 1s, ease-in-out);
// ----------------------------------------------------------------------
@mixin single-transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

// ----------------------------------------------------------------------
// Media Queries
// General Media Queries
$phone-width: 576px; //min width for tablet
$small-tablet-width: 768px; //min width for big tablet
$tablet-width: 992px; //min width for desktop
$desktop-width: 1200px; //min width for bigger screens
// ----------------------------------------------------------------------
@mixin big-phone {
  @media (min-width: $phone-width) {
    @content;
  }
}
@mixin small-tablet {
  @media (min-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

.QuickGuideModal.ant-modal {
    width: 100% !important;
    top: 5% !important;

    @media screen and (max-width:576px){
        top: 100px !important;
    }
}

.QuickGuideModal {
    max-width: 1096px !important;
    max-height: 600px !important;
    width: 100%;
    height: 100%;
    // overflow: auto;
    padding-right: 24px;
    padding-top:37px ;
    
    // .ant-modal-close {
    //     color: #FFFFFF;
    //     position: absolute;
    //     top: 5px;
    //     right: 15px;
    //     z-index: 11111;
    // }

    .ant-modal-body {
        padding: 0px 24px 48px 30px !important;
        overflow: auto;
        max-width: 1096px !important;
        max-height: 700px !important;
        width: 100%;
        height: 100%;
        overflow: auto;

        scrollbar-width:thin !important; 
        scrollbar-color: #8B7EE6 #352E55 !important;

        &::-webkit-scrollbar {
            display: block;
            // width: 8px !important;
            margin-left: 50px;
            margin-right: 50px;
            padding: 50px;
            position: absolute;
            background-color: #352E55;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #8B7EE6;
            border-radius: 8px;
            outline: 4px solid #8B7EE6;
        }

        &::-webkit-scrollbar-track {
            border-radius: 8px;
        }
    }

    .ant-modal-content {
        background-color: transparent !important;
        height: 100%;
        width: 100%;
        box-shadow: none;
        margin-top: 25px;
        position: static;
        padding: 10px;
    }

    .ant-modal-close {
        display: none;
    }

    .QuickGuide_Close {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-self: center;
        margin-bottom: 10px !important;
        right: 25px !important;
        span {
            color:#D1D1D1D1;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin-top: 0px;
            opacity: 1;
            transition: all 0.5s;
            opacity: 0;
            transform: translateX(15px);
        }

        &:hover, 
        &:focus {
            span {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @media screen and (max-width:576px){
            right: 15px !important;
            //top: 10px !important;
            &:focus {
                span {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
            img {
                width: 20px;
                height: 20px;
            }
        }

        @media screen and (min-width:992px){
            right: 25px !important;
            top: 10px !important;
        }
    }

    h4 {
        font-size: 28px;
        line-height: 38.15px;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 32px;
    }
    .gray_desc {
        color: #D1D1D1D1;
        font-size: 18px;
        font-weight:400 ;
        line-height: 24px;
    }

    .GuickGuide_link{ 
        color:#8B7EE6;
        text-decoration: underline;
    }

    .white_desc {
        color: #FFFFFF;
        font-size: 18px;
        font-weight:600 ;
        line-height: 24px;
        letter-spacing: 1.2px;
    }

    .hint {
        background-color: #483E84;
        padding: 12px;
        border-radius: 8px;
        font-weight: 400;
        margin-left: -22px;
    }

    .hint_sec {
        background-color: #843E79;
        padding: 12px;
        border-radius: 8px;
        font-weight: 400;
        margin-left: -22px;
    }

    .info {
        background-color: #483E84;
        padding: 12px;
        border-radius: 8px;
        font-weight: 400;
        margin-left: 35px;
    }

    .ViuLive_logo {
        color:#8B7EE6;
    }

    .guide_list {
        list-style: none;
    }

    .guide_list ul {
        list-style-type: disc;
    }

    .guide_list li {
        cursor: pointer;
    }

    .guide_list li svg {
        margin-right: 8px;
    }

    .guide_list .opend_item {
        text-decoration: none;
    }

    .guide_list .closed_item {
        text-decoration: underline;
    }

    .openedItem {
        transform: rotate(90deg);
    }
    .sub_points {
        text-decoration: none !important;
        cursor: auto !important;
    }
    .note {
        font-weight: 700;
        margin-right: 5px;
    }
    @media screen  and (max-width:576px){
        padding: 15px;

        .ant-modal-body {
            padding: 8px 8px 15px 8px !important;
        }

        .ant-modal-content {
            padding: 5px;
        }

        .QuickGuideModal {
            padding-right: 12px;
            padding-top:15px;
        }

        .QuickGuide_Close {
            //top: -5px;
            right: 2px;
        }
    }
}

.QuickGuideModal_Light {
    background-color: #241F3A !important;
}


.QuickGuideModal_Dark{
    background-color: #241F3A !important;
}





.QuickGuideModal {
    .mb-1 {
        margin-bottom: 32px;
    }

    .mb-2 {
        margin-bottom: 16px;
    }

    .mb-3 {
        margin-bottom: 24px;
    }

    .mb-4 {
        margin-bottom: 8px;
    }

    .mb-5 {
        margin-bottom: 64px;
    }
    .mb-6 {
        margin-bottom: 10px;
    }

    .ml-1 {
        margin-left: 22px;
    } 
    .ml-2 {
        margin-left: 18px;
    }
    .ml-3 {
        margin-left: 50px;
    }
    .ml-4 {
        margin-left: 66px;
    }
    .ml-5 {
        margin-left: 130px;
    }
    .ml-6 {
        margin-left:84px ;
    }


    @media screen and (max-width:576px){
        .mb-1 {
            margin-bottom: 32px;
        }
        .mb-2 {
            margin-bottom: 16px;
        }
        .mb-3 {
            margin-bottom: 24px;
        }
        .mb-4 {
            margin-bottom: 8px;
        }
        .mb-5 {
            margin-bottom: 64px;
        }
        .mb-6 {
            margin-bottom: 10px;
        }
        .ml-1 {
            margin-left: 15px;
        } 
        .ml-2 {
            margin-left: 10px;
        }
        .ml-3 {
            margin-left: 30px;
        }
        .ml-4 {
            margin-left: 40px;
        }
        .ml-5 {
            margin-left: 70px;
        }
        .ml-6 {
            margin-left:40px;
        }
    }
}



@media screen  and (max-width:576px) {
    .QuickGuideModal {
        .ml-1 {
            margin-left: 0;
        }

        .ml-2 {
            margin-left: 0;
        }
    }
}